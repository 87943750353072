@use 'common'

.wrapper
	display: flex
	color: common.$textColor

	&.isLogin
		align-items: center
		justify-content: center
		border: 1px solid black
		text-align: center

.link
	display: inline-block

	&.is_login
		display: inline-flex
		align-items: center
		justify-content: center
		padding: 20px 20px
		border: 1px solid #CECECF
		border-radius: common.$border-radius-md
		min-width: 160px

	&:hover
		.title
			opacity: 1
			color: var(--dark-120-ceros-blue-120, #1400CC)
			// font-weight: 500
			// text-decoration: underline
			// text-underline-offset: 0.5rem

		.description
			opacity: 1

		.arrow
			transform: translateX(.625rem)
			visibility: none
			opacity: 1

.view_logoLogin
	display: flex
	flex-direction: row
	align-items: center
	gap: 8px

.main
	gap: 0rem
	display: flex
	flex-direction: column
	&.view_logo
		display: flex
		// grid-template-columns: minmax(1.875rem, 2.5rem) 1fr

.titleIcon
	display: flex
	align-items: center
	gap: 5px

.content
	background: "#000000"
	&Label
		display: flex
		align-items: center

.icon
	//

.title
	position: relative
	opacity: 1
	transition: opacity .2s
	color: #0A0A0E
	font-family: common.$sourceSans3
	font-size: 1.125rem
	font-style: normal
	font-weight: 400
	line-height: 1.5rem

	&.fontNormal
		font-weight: 500 !important

.arrow
	display: inline-flex
	position: relative
	font-size: .875rem
	color: common.$blue
	opacity: 0
	visibility: hidden
	transition-property: visibility, opacity, transform
	transition-duration: .2s

.description
	opacity: 1
	color: #0A0A0E
	transition: opacity .2s
	width: 100%
	font-family: common.$sourceSans3
	font-size: 1rem
	font-style: normal
	font-weight: 400
	line-height: 1.5rem

.stylesWithDescription
	margin-bottom: 1rem
