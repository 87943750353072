@use 'common'
@use 'sass:math'

$gap: 1.875rem
$sectionHorizontalSpacing: 1.875rem
$menuVerticalSpacing: 1.25rem
$buttonPadding: .625rem

.wrapper
	//

.menu
	position: absolute
	top: 0
	left: 0
	right: 0
	padding: $menuVerticalSpacing 0
	background-color: #ffffff
	color: common.$textColor
	visibility: hidden
	opacity: 0
	transform: translateY(-100%)
	transition-property: visibility, opacity, transform
	transition-duration: .3s
	z-index: 1
	border-radius: common.$border-radius
	box-shadow: common.$box-shadow

.is_open

	.menu
		visibility: initial
		opacity: 1
		transform: translateY(0)

.button
	position: relative
	border: none
	background: transparent
	padding: 0
	font-size: 1rem
	display: flex
	z-index: 0
	padding: $buttonPadding

	&Wrapper
		margin: -$buttonPadding

	@media (min-width: common.$break62)
		display: none

.is_hamburger
	//

.is_close
	position: absolute
	top: ($menuVerticalSpacing - $buttonPadding)
	right: ($sectionHorizontalSpacing - $buttonPadding)
	color: #000000

.sections
	//

.section

	&In
		padding-left: $sectionHorizontalSpacing
		padding-right: $sectionHorizontalSpacing

	&:not(:first-child)
		padding-top: 1.5rem
		// border-top: 1px solid #cfcfcf

	&:not(:last-child)
		padding-bottom: 1.375rem

.primaryLink
	font-weight: 500

.groups
	display: flex
	flex-wrap: wrap
	gap: $gap

.group
	list-style-type: none
	padding: unset
	margin: unset
	margin-top: 1.125rem
	width: 100%
	padding-left: 1rem
	&Item
		font-size: .8125em
		line-height: 1.15
		font-weight: 500

		&Content
			&.view_icon
				display: flex
				align-items: center
				gap: 0.5rem
				margin-left: 0.8rem
				border: 1px solid #CECECF
				margin-right: 1.8rem
				padding-top: 1rem
				padding-bottom: 1rem
				border-radius: 1rem
				display: flex
				justify-content: center

		&:not(:first-child)
			margin-top: 1.125rem

	@media (min-width: common.$break30)
		width: 100%

.actions
	display: flex
	flex-direction: column
	justify-content: center
	flex-wrap: wrap
	padding: 0 $menuVerticalSpacing
	gap: .9375rem
	position: sticky
	bottom: 0
	padding-bottom: 1rem
	background: white
	@media (min-width: common.$break24)
		flex-direction: row

.specialLink

.productsWrapper
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	width: 100%

.products
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	width: 100%

.productLoginLink
	display: flex
