@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

	&.cursorSize
		height: clamp(1.5em, 3.5vw, 2.5em)

.icon
	// Add all svg icons and corresponding dimensions

	&-arrow
		+normalizeSize(19, 15)

	&-checkmark
		+normalizeSize(40, 38)

	&-cerosLogo
		+normalizeSize(59, 14)

	&-close
		+normalizeSize(18, 17)

	&-cross
		+normalizeSize(24, 24)

	&-desktop
		+normalizeSize(24, 20)

	&-education
		+normalizeSize(19, 16)

	&-eye
		+normalizeSize(19, 14)
		font-size: 0.875em

	&-gemmaLogo
		+normalizeSize(187, 84)

	&-hamburger
		+normalizeSize(22, 16)

	&-home
		+normalizeSize(18, 16)

	&-logo
		+normalizeSize(56, 30)

	&-mobile
		+normalizeSize(13, 21)

	&-share
		+normalizeSize(24, 24)

	&-thumbUp
		+normalizeSize(17, 17)

	&-tools
		+normalizeSize(18, 17)

	&-star
		+normalizeSize(39, 40)

	&-wave
		+normalizeSize(495, 466)

	&-playButton
		+normalizeSize(99, 99)

	&-cursor
		+normalizeSize(41, 42)

	&-chatLogo
		+normalizeSize(17, 16)

	&-splashStar
		+normalizeSize(30, 30)

	&-quadrant
		+normalizeSize(60, 60)

	&-cikica
		+normalizeSize(0, 0)

	&-circle
		+normalizeSize(36, 36)

	&-fourTipStar
		+normalizeSize(60, 60)

	&-ball
		+normalizeSize(56, 56)

	&-splashStarOutline
		+normalizeSize(38, 38)

	&-triangle
		+normalizeSize(32, 32)

	&-triangleOutline
		+normalizeSize(33, 35)

	&-checkTrue
		+normalizeSize(16, 16)

	&-checkFalse
		+normalizeSize(16, 16)

	&-checkGemma
		+normalizeSize(16, 16)

	&-rainbow
		+normalizeSize(0, 0)

	&-doiley
		height: 1.25em !important
		+normalizeSize(0, 0)

	&-cloverV2
		+normalizeSize(0, 0)

	&-fourthPtStarV2
		+normalizeSize(0, 0)

	&-fourthPtStar
		+normalizeSize(0, 0)

	&-quadrant
		+normalizeSize(16, 16)

	&-gummyBear
		+normalizeSize(0, 0)

	&-sixtuplet
		+normalizeSize(0, 0)

	&-clover
		+normalizeSize(16, 16)

	&-triangle
		+normalizeSize(16, 16)

	&-flower
		+normalizeSize(16, 16)

	&-curvedSquare
		+normalizeSize(16, 16)

	&-arch
		+normalizeSize(16, 16)

	&-starV2
		+normalizeSize(16, 16)

	&-dojaDogCursos
		+normalizeSize(0,0)

	&-dwightCursor
		+normalizeSize(0,0)

	&-pinkPantherCursor
		+normalizeSize(0,0)
