@use 'common'
@use 'sass:math'

.wrapper
	background-color: common.$dark
	color: #ffffff
	padding: 5.5em 0 4em

.footerLinksContainer
	display: flex
	flex-direction: column
	align-items: flex-start
	@media (min-width: common.$break48)
		flex-direction: row

.footerLeftSide
	display: flex
	flex-direction: column
	gap: 2.5rem
	width: 50%
	margin-bottom: 0

.socialLinks
	display: flex
	align-items: center
	gap: 1.8rem
	@media (min-width: common.$break48)
		gap: 1.7rem

.footerLinks
	display: grid
	grid-template-columns: auto
	width: 100%
	@media (min-width: common.$break48)
		grid-template-columns: auto auto
		justify-content: space-evenly
		row-gap: 3.5rem
		column-gap: 8.12rem

.footerLinksWrapper
	display: flex
	flex-direction: row
	gap: 1.12rem
	margin-top: 3rem
	@media (min-width: common.$break48)
		margin-top: 0
		flex-direction: row
		width: 100%

.topLinks
	display: flex
	flex-direction: column

.title
	font-family: common.$objectSans
	margin: 0
	width: 100%
	margin-bottom: 0.875rem
	font-size: 1.5rem
	line-height: 2rem
	color: #fff
	@media (min-width: common.$break48)
		margin-bottom: 0

.link
	font-family: common.$sourceSans3
	width: 100%
	padding: 0.5rem 0.75rem 0.5rem 0
	opacity: 0.8
	font-size: 1.125rem
	line-height: 1.625rem
	color: #9D9D9F
	&:hover
		opacity: 0.4

	@media (min-width: common.$break48)
		padding: 0.5rem 0.75rem 0 0
		width: fit-content

.bottomLinksWrapper
	display: flex
	flex-direction: column-reverse
	border-top: 1px solid rgba(255,255,255,0.3)
	margin-top: 3rem
	width: 100%
	justify-content: space-between
	@media (min-width: common.$break48)
		margin-top: 6rem
		padding-top: 2rem
		flex-direction: row
		align-items: center

.copyright
	opacity: 0.5
	padding: 2rem 0.5rem 0 0
	@media (min-width: common.$break48)
		margin: 0
		margin-right: 4rem
		padding: 0.5rem 0 0 0

.bottomLinks
	display: grid
	grid-template-columns: auto auto
	padding-top: 1.25rem
	@media (min-width: common.$break48)
		padding-top: 0
		display: flex
		flex-wrap: wrap
		flex-direction: row
		align-items: center
		.link
			padding: 0.5rem 0 0 2.13rem

.logoContainer
	max-width: 150px

.bottomLink
	width: 100%
	padding: 0.5rem 0.75rem 0.5rem 0
	opacity: 0.8
	font-size: 1rem
	line-height: 1.5rem
	color: #CECECF
	&:hover
		opacity: 0.4

	@media (min-width: common.$break48)
		padding: 0.5rem 2rem 0 0
		width: fit-content

.bottomLink:nth-child(odd)
	@media (max-width: common.$break48)
		padding: 0.5rem 3.75rem 0.5rem 0rem
