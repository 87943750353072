@use 'common'

.wrapper
	display: flex
	flex-direction: column

	&.is_gemma
		// background-color: #F8F8FF

.main
	padding-top: 1.8125rem // spacing to the bottom of logo = 75 px
	flex-grow: 1

	&.isLoginPage
		padding-top: 0 !important
