@use 'common'
@use 'sass:color'

.wrapper
	a
		font-weight: 600
		transition: color .2s

	&.is_theme_light a
		color: common.$blue

		&:hover
			color: color.adjust(common.$blue, $lightness: 15%)

	&.is_theme_dark a
		color: inherit

		&:hover
			text-decoration: underline

	h1
		+common.title($isLarge: true)

	h2
		+common.heading2

	h3
		+common.heading3

	h4
		+common.heading4

	em
		font-style: normal
		color: common.$blue

	p
		opacity: var(--text-opacity)

	[data-contember-align="center"]
		text-align: center
	[data-contember-align="end"]
		text-align: end
