@use 'common'

.outline_wrapper
	position: relative
	display: inline-block

	span
		width: 100%
		height: 100%
		padding: 10px 0px
		border: none
		background: none
		outline: none
		position: relative
		z-index: 1

	svg
		// transform: rotate(-2.147deg)
		position: absolute
		// bottom: -50% // --> For Arrows
		left: -5%
		width: 120%
		height: 100% // -=> For Arrows
		pointer-events: none

		@media (max-width: common.$break48)
			bottom: -60% // --> For Arrows
			height: 100%

	path
		// stroke-width: 4px
		stroke-dasharray: 0 1500
		// animation: draw 1.5s forwards

		&.is_Reset
			animation: none

		&.is_inView
			animation: draw 1.5s forwards

@keyframes draw
	from
		stroke-dasharray: 0 1500
	to
		stroke-dasharray: 1500 1500
