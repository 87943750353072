@use 'common'

$-light-background-color: #ffffff
$-dark-background-color: #000000

.wrapper
	display: grid
	grid-template-rows: 1fr

	&.is_theme_light
		+common.headingsColor('light')
		color: common.$textColor
		background-color: $-light-background-color
		--text-opacity: 1

	&.is_theme_dark
		+common.headingsColor('dark')
		color: #ffffff // @TODO: use variable
		background-color: $-dark-background-color
		--text-opacity: 0.8

:root:has(.wrapper.is_theme_light)
	background-color: $-light-background-color
	color-scheme: light

:root:has(.wrapper.is_theme_dark)
	background-color: $-dark-background-color
	color-scheme: dark
