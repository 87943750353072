@use 'common'
@use 'sass:color'

$subNavigationPeakSize: 1.25rem

.wrapper
	position: relative
	--Header-background-color: #ffffff

	&.is_theme_dark
		--Header-background-color: #{common.$dark-theme-gradient-start-color}

	&::before
		content: ''
		position: absolute
		inset: 0
		background-color: var(--Header-background-color)
		border-bottom: 1px solid #dfdfdf
		transition: opacity 0.2s
		opacity: 1
		z-index: 0

	&.is_theme_dark::before
		border-bottom-color: color.adjust(common.$dark-theme-gradient-start-color, $lightness: 10%)

	&.is_home::before
		opacity: 0

.header
	position: relative
	display: flex
	justify-content: space-between
	align-items: center
	padding: 1rem 0
	z-index: 10

.logo
	margin: 0
	transition: opacity .2s

	.is_theme_dark &
		filter: invert(1) // Very shortsighted, but it works for now

	&:hover
		opacity: 0.5

	&_in
		display: flex

.logo_in
	max-width: 5.93069rem
	max-height: 1.3125rem

.links
	display: none

	&Item
		position: relative
		z-index: 1

	@media (min-width: common.$break62)
		display: flex
		justify-content: center
		gap: 2rem
		flex-wrap: wrap
	@media (min-width: common.$break75)
		gap: 3rem
	@media (min-width: common.$break81)
		gap: 5rem

.link
	white-space: nowrap
	// padding: .625rem 1.875rem
	transition: opacity .2s
	color: var(--neutral-black, #0A0A0E)
	font-size: 1.125rem
	font-weight: 400
	font-family: common.$sourceSans3
	line-height: 1.625rem

	// &.is_active
	// 	font-weight: 700
	&:hover
		color: var(--dark-120-ceros-blue-120, #1400CC)
		font-weight: 400
		// text-decoration: underline
		// text-underline-offset: 0.5rem

.column
	display: flex
	align-items: center

.actions
	display: flex
	gap: 1rem

	.is_mobile
		@media (min-width: common.$break62)
			display: none

	.is_desktop
		@media not all and (min-width: common.$break62)
			display: none

.login, .linksItem
	&:hover, &:focus-visible
		.peakShape, .subNavigation
			visibility: initial
			opacity: 1

.login
	position: relative

.specialLink
	display: inline-block
	text-decoration: none
	font-weight: 500
	padding-right: 1.12rem
	padding-left: 1.12rem
	padding-bottom: 0.5rem
	padding-top: 0.5rem
	font-size: 1.125rem
	line-height: 1.77em
	white-space: nowrap
	background: blue
	color: white
	border-radius: 0.5rem
	margin-right: 0.875rem

.subNavigation,
.peakShape
	transition-property: visibility, opacity
	transition-duration: .2s

.subNavigation
	$outerHorizontalPosition: -100%
	min-width: 12.8rem
	position: absolute
	top: calc(150% + $subNavigationPeakSize)
	left: 50%
	transform: translateX(-50%)
	background-color: #ffffff
	border-radius: 2rem
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12)
	opacity: 0
	visibility: hidden
	z-index: 0

	&.is_login
		top: calc(105% + $subNavigationPeakSize)
		min-width: max-content

	&.is_first, &.is_last
		transform: unset

	&.is_first
		left: $outerHorizontalPosition

	&.is_last
		left: auto
		right: $outerHorizontalPosition

	&.is_desktop
		@media not all and (min-width: common.$break62)
			display: none

.peakShape
	position: absolute
	// display: none
	top: calc(100% + $subNavigationPeakSize)
	left: 0
	right: 0
	height: 2.5rem
	// visibility: hidden
	opacity: 0
	z-index: 1

	&::before
		content: ''
		position: absolute
		z-index: 0
		width: $subNavigationPeakSize
		height: $subNavigationPeakSize
		left: 50%
		background-color: transparent
		// box-shadow: common.$box-shadow
		transform: translate(-50%, -50%) rotate(45deg)
		clip-path: polygon(-100% 200%, -100% -100%, 200% -100%)

	// Mouse leave/enter filler between menu item and submenu box to prevent flashing while moving
	&::after
		content: ''
		position: absolute
		inset: auto 0 100%
		width: 100%
		height: $subNavigationPeakSize

.mobileHeader

	@media (min-width: common.$break62)
		display: none
