@use 'common'

.wrapper
	width: 100%

.main
	display: flex
	justify-content: space-between
	align-items: center

.column
	display: flex
	align-items: flex-end
	line-height: 1

	&.is_cerosLogo
		align-items: flex-start
		flex-direction: column
		gap: .375rem

		@media (min-width: common.$break24)
			flex-direction: row

.link
	$spacing: .625rem

	display: flex
	padding: $spacing
	margin: -$spacing

.text
	display: flex
