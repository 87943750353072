=rotateAnimation
	animation-name: rotate
	animation-duration: 4s
	animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6)

	@keyframes rotate
		0%
			transform: rotate(0deg)

		100%
			transform: rotate(359deg)

=scaleAnimation($scale: 'increasing')
	animation-duration: 2.5s
	animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6)

	@if ($scale == 'increasing')
		animation-name: increasingScale
		@keyframes increasingScale
			0%
				transform: scale(1)

			50%
				transform: scale(1.3)

			100%
				transform: scale(1)

	@else if ($scale == 'decreasing')
		animation-name: decreasingScale
		@keyframes decreasingScale
			0%
				transform: scale(1)

			50%
				transform: scale(0.7)

			100%
				transform: scale(1)


=rotateHumbleAnimation
	animation-name: rotateDeg
	animation-duration: 3s
	animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6)
	animation-iteration-count: infinite

	@keyframes rotateDeg
		0%
			transform: rotate(33deg)

		25%
			transform: rotate(24deg)

		50%
			transform: rotate(33deg)

		75%
			transform: rotate(42deg)

		100%
			transform: rotate(33deg)
			
=fadeInAnimation
	animation-name: fadeIn
	animation-duration: 1s
	animation-timing-function: ease-out
	animation-fill-mode: both

	@keyframes fadeIn
		0%
			opacity: 0
		100%
			opacity: 1