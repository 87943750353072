@use 'common'

.outline_wrapper
    display: inline-block
    // position: relative

    .text
        position: relative

    .tooltip_wrapper
        position: absolute
        top: -30px
        left: 0
        +common.fadeInAnimation

        display: flex
        transform: rotate(-3.753deg)
        padding: 10px 14px
        justify-content: center
        align-items: center
        gap: 8px
        background: #907EFF
        border-radius: 300px

        span
            color: var(--neutral-white, #FFF)
            text-align: center
            leading-trim: both

            text-edge: cap
            font-size: 16px
            font-style: normal
            font-weight: 700
            line-height: 16px
            letter-spacing: -0.64px

        @media (min-width: common.$break38)
            top: -15.5%
            left: -3.5%

        @media (min-width: common.$break62)
            top: -6.5%
            left: 1.5%
            min-width: max-content
