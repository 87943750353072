@use 'common'

.wrapper
	color: #000000

.main
	display: flex
	gap: 1.25rem

.group
	min-width: 13.4rem
	width: 50%
	flex-grow: 1

	&Title
		font-family: common.$sourceSans3Stack
		font-size: 1rem
		margin: 0
		margin-bottom: 1.5rem
		text-transform: uppercase
		color: #666666
		font-weight: 700

.list
	list-style-type: none
	padding: initial
	margin: unset

.productsList
	display: flex
	gap: 1rem

.item

	&:not(:first-child)
		margin-top: .9375rem

		&.has_icon
			margin-top: 2.5rem

.itemSecondary
	padding: 2rem
	border-radius: 1.5rem
	min-width: 12.8rem
	opacity: 0.75

	&:hover
		opacity: 1

	&:not(:first-child)
		margin-top: 0rem

		&.has_icon
			margin-top: 0rem

.groupTitle
	font-family: common.$objectSans
	text-transform: capitalize
	font-size: 2rem
	font-style: normal
	font-weight: 400
	line-height: 2.5rem
	display: flex
	align-items: center
	gap: 5px
	color: #0A0A0E

.header
	display: flex
	flex-direction: column
