@use 'common'

.wrapper
	color: var(--HighlightColor-fillColor, currentColor)
	font-size: inherit
	font-family: monospace, serif
	z-index: 1
	background-color: var(--HighlightColor-backgroundColor, transparent)

	&.hasBackground
		border-radius: .3125rem
		padding: .3125em
