@use 'common'
@use './SubNavigation.module' as subNavigation

$gap: 1.25rem

.wrapper
	//

.main
	//

.list
	display: flex
	gap: $gap
	flex-wrap: wrap
	list-style-type: none
	padding: common.$headerSubNavigationPadding - subNavigation.$outterPadding
	margin: unset
	background-color: #f4f4f4
	border-radius: common.$border-radius

.item
	width: calc(50% - $gap)
	flex-grow: 1
