@use 'common'

.wrapper
	//
	max-width: 12.8rem
	display: flex
	justify-content: center
	align-items: center
	
.list
	list-style-type: none
	padding: unset
	margin: unset

.item
	// border: 1px solid var(--neutral-black-20, #CECECF)
	background: var(--neutral-white, #FFF)
	border-radius: 1rem
	display: flex
	flex-direction: row
	align-items: center
	justify-content: center
	&:not(:first-child)
		margin-top: .9375rem
		&.has_icon
			margin-top: 0.9375rem
	&:hover
		background: var(--neutral-black-4, #F5F5F5)
