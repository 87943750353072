@use 'common'
@use 'sass:color'

.wrapper
	position: relative
	line-height: 1.625
	border-radius: 0.625em
	border: 1px solid transparent
	min-height: var(--Button-height)
	min-width: 6em
	margin: 0
	font-size: 1.125rem
	line-height: 1.625rem
	padding: .5em 1.5em
	gap: 1em
	display: inline-flex
	align-items: center
	background-color: transparent
	overflow: hidden
	transition: background-color .3s
	font-family: common.$sourceSans3
	font-weight: 500
	cursor: pointer

	&:hover
		background-color: rgba(#000000, 0.1)

	&.is_fullWidth
		width: 100%

	&.is_size_small
		--Button-height: 2.1875em

	&.is_size_medium
		--Button-height: 2.875em

	&.is_fill_transparent
		color: #000000

	&.is_fill_dark
		background-color: #0a0a0e
		color: #ffffff

		&:hover
			background-color: #2e2e35

	&.is_fill_light
		background-color: #ffffff
		color: #000000

		&:hover
			color: white
			background-color: black

	&.is_fill_blue
		background-color: common.$blue
		color: #ffffff
		border: none

		&:hover
			background-color: #0912c5

	&.is_fill_chartreuseGreen
		background-color: common.$chartreuse
		color: #000000

		&:hover
			background-color: #AAD913

	&.is_outline
		border-color: #888c9a

		&:hover
			&.is_fill_light
				border-color: transparent

	&.is_align_start
		justify-content: flex-start
		text-align: start

	&.is_align_center
		justify-content: center
		text-align: center

	&.is_bland
		color: #888c9a

.content
	// font-size: 0.875em
	display: contents

@keyframes ripple
	0%
		transform: scale(0)
		opacity: var(--Button-ripple-opacity)
	30%
		opacity: var(--Button-ripple-opacity)
	70%
		transform: none
	100%
		opacity: 0

.ripple
	--Button-ripple-opacity: 0.2
	position: absolute
	top: calc(-0.5 * var(--Button-ripple-size) + var(--Button-ripple-y))
	left: calc(-0.5 * var(--Button-ripple-size) + var(--Button-ripple-x))
	width: var(--Button-ripple-size)
	aspect-ratio: 1 / 1
	border-radius: 50%
	background-color: currentColor
	animation: ripple 0.6s forwards ease-in
	pointer-events: none

	@media (prefers-reduced-motion)
		display: none
