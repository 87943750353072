@use 'common'

$appleClassNames: (".is_platform_ios, .is_platform_mac")
// When adding background color and outline, the styles are a bit offset on Apple devices, even on chrome browser and safari (which is standard...)
$appleInset: calc(-1 * var(--BackgroundColorText-borderWidth)) -0.3125rem calc(0.5 * var(--BackgroundColorText-borderWidth))
$nonAppleInset: 0 calc(-0.5 * var(--BackgroundColorText-borderWidth)) calc(-1.25 * var(--BackgroundColorText-borderWidth))

.outline_wrapper
	position: relative
	display: inline-block
		
	span
		width: 100%
		height: 100%
		padding: 10px 20px
		border: none
		background: none
		outline: none
		position: relative
		z-index: 1

	svg
		transform: rotate(-2.147deg)
		position: absolute
		top: -25%
		
		left: 0
		width: 100%
		height: 125% 
		
		pointer-events: none

		@media (max-width: common.$break48)
			top: -10%
			height: 100%
		

	path
		stroke-width: 4px
		stroke-dasharray: 0 1500
		// animation: draw 1.5s forwards

		&.is_Reset
			animation: none

		&.is_inView 
			animation: draw 1.5s forwards


@keyframes draw
	from
		stroke-dasharray: 0 1500
	to
		stroke-dasharray: 1500 1500


.wrapper
	--BackgroundColorText-borderWidth: .375rem

	display: inline-block
	position: relative
	z-index: 0
	margin-inline: .625rem

	@media not all and (min-width: common.$break48)
		&::before,
		&::after
			margin-inline: calc(-1 * var(--BackgroundColorText-spacingInlineMobile, 0))

	@media (min-width: common.$break48)
		&::before,
		&::after
			margin-inline: calc(-1 * var(--BackgroundColorText-spacingInline, 0))

	&::before
		content: ''
		position: absolute
		border-radius: 8.75rem
		z-index: -1

	&.is_outline
		&:is(#{$appleClassNames})
			&::after
				inset: $appleInset

		&:not(#{$appleClassNames})
			&::after
				inset: $nonAppleInset

	@media (min-width: common.$break48)
		--BackgroundColorText-borderWidth: .625rem

	&.is_outline

		&::after
			content: ''
			position: absolute
			border-width: var(--BackgroundColorText-borderWidth)
			border-style: solid
			border-color: var(--BackgroundColorText-borderColor, transparent)
			border-radius: 8.75rem

	&:not(.is_outline)
		&::before
			background-color: var(--BackgroundColorText-backgroundColor, transparent)

		&:is(#{$appleClassNames})
			&::before
				inset: $appleInset

		&:not(#{$appleClassNames})
			&::before
				inset: $nonAppleInset


