@use 'common'

.outline_wrapper
    display: inline-block
    position: relative

    .tooltip_wrapper
        position: absolute
        right: -40%
        bottom: -30%
        +common.fadeInAnimation

        display: flex
        transform: rotate(-3.753deg)
        padding: 10px 14px
        justify-content: center
        align-items: center
        gap: 8px
        background: #907EFF
        border-radius: 300px

        span
            color: var(--neutral-white, #FFF)
            text-align: center
            leading-trim: both

            text-edge: cap
            font-size: 1rem
            font-style: normal
            font-weight: 700
            line-height: 1rem
            letter-spacing: -0.64px

        @media (min-width: common.$break38)
            bottom: -10%

        @media (min-width: common.$break62)
            bottom: -2.5%
            right: -40%
            min-width: max-content
