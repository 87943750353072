@use 'common'

=hideBreakElement
	br
		display: block

.wrapper
	// font-size: 1.125rem

	&.is_always
		//

	&.is_768
		@media not all and (min-width: common.$break48)
			+hideBreakElement

	&.is_992
		@media not all and (min-width: common.$break62)
			+hideBreakElement
	&>p
		font-size: 1.125rem
