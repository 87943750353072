@use 'common'

.wrapper
	display: inline-flex
	font-size: 1.375rem
	color: common.$chartreuse
	position: relative
	z-index: 0

	// offsetting the icon to the left by 0.04em and to the right by -0.06em for the special case - in the word "box"
	// @TODO: find a better way to do this
	margin-left: 0.02em
	// margin-right: -0.08em

	&.is_inView
		+common.rotateAnimation

	&.no_animation
		animation: none

	.is_hidden
		display: inline-flex
		visibility: hidden
		opacity: 0
		position: absolute
		z-index: 0

	@media (min-width: common.$break22)
		font-size: 2.5rem

		&.bigger
			font-size: 2.5625rem

	@media (min-width: common.$break48)
		font-size: 2.625rem

		&.bigger
			font-size: 3.625rem

	@media (min-width: common.$break62)
		font-size: 3.3125rem

		&.bigger
			font-size: 4.3125rem
