@use 'common'

$outterPadding: .625rem

.wrapper
	border-radius: common.$border-radius

.groups, .login
	padding: common.$headerSubNavigationPadding

.specialGroup
	padding: $outterPadding
